<template>
  <!-- Banner Section Start -->
  <!-- ======= Hero Section ======= -->
  <section
    id="hero"
    class="hero d-flex align-items-center px-4"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-6 d-flex flex-column justify-content-center">
          <h1 data-aos="fade-up">
            Nous mettons a disposition une api des plus completes
          </h1>
          <h2
            data-aos="fade-up"
            data-aos-delay="400"
          >
            Ajoutez un couche de securite supplementaire a votre application
          </h2>
          <div
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <div class="d-flex justify-content-between w-100">
              <router-link
                class="btn btn-primary scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                to="/register"
              >
                <span>Lancez-vous</span>
                <i class="bi bi-arrow-right" />
              </router-link>
              <router-link
                class="btn btn-primary scrollto d-inline-flex align-items-center justify-content-center align-self-center"
                to="/testit" id="submit"
              >
                <span>Essayez la solution</span>
                <i class="bi bi-arrow-right" />
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 hero-img"
          data-aos="zoom-out"
          data-aos-delay="200"
        >
          <img
            src="assets/img/hero-img.png"
            class="img-fluid"
            alt=""
          >
        </div>
      </div>
    </div>

  </section><!-- End Hero -->
  <!-- Banner Section End -->
</template>

<script>

export default {
  name: 'Banner',
  components: {
  },
}
</script>
